import { startOfDay } from "date-fns";
import { isAfter, isBefore, isWithinInterval, subWeeks } from "date-fns/esm";
import { DateRange } from "rsuite/esm/DateRangePicker";

export const LIVE_USER_ID = "VTXf2XZvKsa5PpFanlZUPa96vqG3";

export enum LiveEvents {
  OTB2023 = "OTB2023",
  PDGAWORLDS2023 = "PDGAWORLDS2023",
  USDGC2023 = "USDGC2023",
  DDO2024 = "DDO2024",
  OTB2024 = "OTB2024",
  DISTANCE2024 = "DISTANCE2024",
  WORLDS2024 = "PDGAWORLDS2024",
}

export type LiveEvent = {
  event: LiveEvents;
  dates: DateRange;
  label: string;
  queryLimit: number;
};

export const liveEvents: LiveEvent[] = [
  {
    event: LiveEvents.OTB2023,
    dates: [new Date(2023, 4, 10), new Date(2023, 4, 15)],
    label: "OTB Open 2023",
    queryLimit: 3100,
  },
  {
    event: LiveEvents.PDGAWORLDS2023,
    dates: [new Date(2023, 7, 22), new Date(2023, 8, 5)],
    label: "PDGA Worlds 2023",
    queryLimit: 4500,
  },
  {
    event: LiveEvents.USDGC2023,
    dates: [new Date(2023, 9, 1), new Date(2023, 9, 10)],
    label: "USDGC 2023",
    queryLimit: 500,
  },
  {
    event: LiveEvents.DDO2024,
    dates: [new Date(2024, 4, 1), new Date(2024, 4, 6)],
    label: "DDO 2024",
    queryLimit: 3000,
  },
  {
    event: LiveEvents.OTB2024,
    dates: [new Date(2024, 4, 15), new Date(2024, 4, 20)],
    label: "OTB Open 2024",
    queryLimit: 3000,
  },
  {
    event: LiveEvents.DISTANCE2024,
    dates: [new Date(2024, 7, 17), new Date(2024, 7, 19)],
    label: "PDGA Worlds Distance Competition 2024",
    queryLimit: 3000,
  },
  {
    event: LiveEvents.WORLDS2024,
    dates: [new Date(2024, 7, 21), new Date(2024, 7, 26)],
    label: "PDGA Worlds 2024",
    queryLimit: 5000,
  },
];

const splitArray: <T>(array: T[], predicate: (elem: T) => boolean) => [T[], T[]] = (
  array,
  predicate,
) => {
  return array.reduce(
    ([pass, fail], elem) => {
      return predicate(elem) ? [pass, [...fail, elem]] : [[...pass, elem], fail];
    },
    [[], []],
  );
};
const today = new Date();

export const [pastEvents, futureEvents] = splitArray(liveEvents, (event) =>
  isBefore(today, startOfDay(event.dates[1])),
);

const [nextEvent] = futureEvents;

export const isEventLive =
  nextEvent && isWithinInterval(today, { start: nextEvent.dates[0], end: nextEvent.dates[1] });

export const isEventUpcoming =
  !isEventLive &&
  nextEvent &&
  isAfter(today, startOfDay(subWeeks(nextEvent.dates[0], 1))) &&
  isBefore(today, nextEvent.dates[0]);

export const currentEvent = isEventUpcoming || isEventLive ? nextEvent : pastEvents.at(-1);

export function getEventTitle(liveEvent: LiveEvents) {
  const liveEventData = liveEvents.find((event) => event.event === liveEvent);
  return liveEventData?.label ?? "";
}

export function getLiveRouteUrl() {
  const liveEventData = liveEvents.find((event) => event.event === currentEvent?.event);
  const baseUrl = window.location.origin;
  return `${baseUrl}/live/${liveEventData?.event}`;
}
