import { FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { AnalysisSet, ThrowSummary } from "../../model/throwSummary";
import { CoreStats } from "../../model/CoreStats";
import coreMetricsConverter, { removeCoreMetrics } from "./coreMetrics";

export interface ClientCoreMetrics {
  id?: string;
  spinRpm: number; // Hz
  speedMph: number;
  speedKmh: number;
  advanceRatio: number;
  hyzerAngle: number; // degrees
  noseAngle: number; // degrees
  launchAngle: number; // degrees
  wobble: number;
  distanceFeet: number;
  distanceMeters: number;
  originalSummary: ThrowSummary;
}

export interface CoreMetrics extends ClientCoreMetrics, CoreStats {}

export type ThrowAnalysis = AnalysisSet & CoreMetrics;

export const throwAnalysisConverter: FirestoreDataConverter<ThrowAnalysis, AnalysisSet> = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<ThrowAnalysis, AnalysisSet>,
    options: SnapshotOptions,
  ): ThrowAnalysis => {
    const data = snapshot.data(options);
    return {
      ...data,
      id: snapshot.id,
      throwTime: data.avg?.throwTime ?? data.createTime,
      ...(coreMetricsConverter(snapshot.id, data.avg) ?? {}),
    }; // Adjust this mapping as necessary
  },
  toFirestore: (modelObject: ThrowAnalysis): AnalysisSet => {
    return removeCoreMetrics(modelObject);
  },
};
