export const USERS_PATH = "/users/";
export const DISC_PRESET_PATH = "/users/{uid}/presets/{discId}";
export const STOCK_SHOTS_PATH = "/users/{uid}/stock-shots";
export const DISC_PRESETS_COLLECTION_PATH = "/users/{uid}/presets";
export const THROW_SUMMARY_COLLECTION_PATH = "/users/{uid}/throw-summary/";
export const ANALYSIS_SET_COLLECTION_PATH = "/users/{uid}/analysis-set/";
export const USER_DEVICES_COLLECTION_PATH = "/users/{uid}/devices/";
export const DEVICES_COLLECTION_PATH = "/devices/";
export const DEVICES_CALIBRATION_COLLECTION_PATH = "/users/{uid}/devices/{deviceId}/calibration/";
export const STRIPE_CHECKOUT_SESSION_COLLECTION_PATH =
  "/stripe/data/customers/{uid}/checkout_sessions";
export const LEADERBOARD_USER_METADATA_COLLECTION_PATH = "/leaderboard/users/userMetadata";
export const LEADERBOARD_STORE_METADATA_COLLECTION_PATH = "/leaderboard/users/storeMetadata";
export const LEADERBOARD_WEEKLY_COLLECTION_PATH = "/leaderboard/dates/weekly/";

export const USER_THROW_VIDEO_STORAGE_PATH = "/throw-video/{uid}/{throwId}";
