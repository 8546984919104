import { DocumentReference, doc } from "firebase/firestore";
import {
  getAnalysisSetCollection,
  getUserThrowSummaries,
  getUserDiscPresets,
  getUsersCollection,
  getUserStockShots,
} from "./collections";
import { UserProfile } from "firebase/auth";
import { ThrowAnalysis, throwAnalysisConverter } from "./converters/analysisSet";
import { throwSummaryConverter } from "./converters/throwSummary";
import { ThrowSummary } from "../model/throwSummary";
import { DiscPreset, DiscPresetDocumentData } from "../model/discs";
import { discPresetConverter } from "./converters/discPreset";
import { StockShotDocumentData, stockShotConverter } from "./converters/stockShot";

export function getUserDoc(uid: string): DocumentReference<UserProfile> | null {
  return uid ? doc(getUsersCollection(), uid) : null;
}

export function getUserStockShot(
  uid: string,
  id?: string,
): DocumentReference<StockShotDocumentData> | null {
  const stockShotsRef = getUserStockShots(uid);
  return stockShotsRef && id ? doc(stockShotsRef, id).withConverter(stockShotConverter) : null;
}

export function getAnalysisSet(uid: string, id?: string): DocumentReference<ThrowAnalysis> | null {
  const analysisSetRef = getAnalysisSetCollection(uid);
  return analysisSetRef && id
    ? doc(analysisSetRef, id).withConverter(throwAnalysisConverter)
    : null;
}

export function getThrowSummary(uid: string, id?: string): DocumentReference<ThrowSummary> | null {
  const summariesRef = getUserThrowSummaries(uid);
  return summariesRef && id ? doc(summariesRef, id).withConverter(throwSummaryConverter) : null;
}

export function getUserDiscPreset(
  uid: string,
  discId?: string,
): DocumentReference<DiscPreset, DiscPresetDocumentData> | null {
  const discPresetsRef = getUserDiscPresets(uid);
  return discPresetsRef && discId
    ? doc(discPresetsRef, discId).withConverter(discPresetConverter)
    : null;
}
